/*
 * @Author: zhanghan
 * @Date: 2022-03-28 09:50:50
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-02 15:00:03
 * @Descripttion: 框架数据全局状态管理
 */
import { Module } from 'vuex'
import LayOutFactoryStateTypes from './types'
import RootStateTypes from '../../types'

// Create a new store Modules.
const LayOutFactoryModule: Module<LayOutFactoryStateTypes, RootStateTypes> = {
  namespaced: true,
  state: {
    menuState: false
  },
  mutations: {
    SET_MENU_STATE(state: LayOutFactoryStateTypes, menuState: boolean) {
      state.menuState = menuState
    }
  },
  actions: {}
}

export default LayOutFactoryModule
