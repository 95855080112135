/*
 * @Author: zhanghan
 * @Date: 2022-02-24 19:49:47
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-05-07 15:28:10
 * @Descripttion:
 */
import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '首页',
      keepAlive: true
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/Product/Product.vue'),
    meta: {
      title: '产品详情'
    }
  },
  {
    path: '/productClass',
    name: 'ProductClass',
    component: () => import('@/views/Product/ProductClass.vue'),
    meta: {
      title: '产品分类',
      keepAlive: true
    }
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('@/views/Work/Work.vue'),
    meta: {
      title: '作品详情'
    }
  },
  {
    path: '/workClass',
    name: 'WorkClass',
    component: () => import('@/views/Work/WorkClass.vue'),
    meta: {
      title: '优秀作品',
      keepAlive: true
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/Service/Service.vue'),
    meta: {
      title: '服务详情'
    }
  },
  {
    path: '/serviceClass',
    name: 'ServiceClass',
    component: () => import('@/views/Service/ServiceClass.vue'),
    meta: {
      title: '服务列表',
      keepAlive: true
    }
  },
  {
    path: '/person',
    name: 'Person',
    component: () => import('@/views/Person/Person.vue'),
    meta: {
      title: '成员介绍'
    }
  },
  {
    path: '/personClass',
    name: 'PersonClass',
    component: () => import('@/views/Person/PersonClass.vue'),
    meta: {
      title: '团队',
      keepAlive: true
    }
  },
  {
    path: '/new',
    name: 'New',
    component: () => import('@/views/New/New.vue'),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/newClass',
    name: 'NewClass',
    component: () => import('@/views/New/NewClass.vue'),
    meta: {
      title: '新闻分类',
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About/About.vue'),
    meta: {
      title: '关于',
      keepAlive: true
    }
  },
  {
    path: '/vuex',
    name: 'Vuex',
    component: () => import('@/views/Vuex.vue'),
    meta: {
      title: 'Vuex'
    }
  },
  {
    path: '/axios',
    name: 'Axios',
    component: () => import('@/views/Axios.vue'),
    meta: {
      title: 'Axios'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Test.vue'),
    meta: {
      title: 'Test'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404-找不到页面'
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
