/*
 * @Author: zhanghan
 * @Date: 2019-07-26 02:04:09
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-03-04 11:38:33
 * @Descripttion: 访客ip数据
 */

import { Bmob } from '@/api/baseConfig/baseConfig'

const tableName = 'ip_info'
const query = Bmob.Query(tableName)

const Ip = {
  get: (params) => {
    return new Promise((resolve, reject) => {
      for (const key in params) {
        params[key] && query.equalTo(key, '==', params[key])
      }
      query
        .find()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 新增客户联系数据
  set: (params) => {
    return new Promise((resolve, reject) => {
      for (const key in params) {
        params[key] && query.set(key, params[key])
      }
      query
        .save()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  delete: () => {}
}

export default Ip
