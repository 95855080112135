/*
 * @Author: zhanghan
 * @Date: 2019-07-26 02:04:09
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-03-10 09:36:15
 * @Descripttion: Bmob 初始化函数
 */

// import Bmob from 'hydrogen-js-sdk'
import { SecretKey, SafeKey, ApplicationID, RESTAPIKey } from '@/api/baseConfig/appkey'

window.Bmob.initialize(SecretKey, SafeKey) // 2.0.0 ↑
//  Bmob.initialize(ApplicationID,RESTAPIKey);//2.0.0 ↓

const initMasterBomb = function (MasterKey) {
  window.Bmob.initialize(SecretKey, SafeKey, MasterKey) // 2.0.0 ↑
  // Bmob.initialize(ApplicationID,RESTAPIKey,MasterKey);//2.0.0 ↓
}

const initBmob = function () {
  window.Bmob.initialize(SecretKey, SafeKey) // 2.0.0 ↑
  // Bmob.initialize(ApplicationID,RESTAPIKey);//2.0.0 ↓
}

const { Bmob } = window

export { Bmob, initMasterBomb, initBmob }
