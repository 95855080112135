<!--
 * @Author: zhanghan
 * @Date: 2022-01-27 18:44:26
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-05-11 13:34:21
 * @Descripttion: 主体框架
-->
<template>
  <div class="main">
    <!-- 手机端的伸缩菜单 -->
    <MobMenu closeOnClickModal />
    <!-- 滚动部分 -->
    <el-scrollbar
      ref="scrollbarRef"
      :class="['main-container', reactiveData.menuState ? 'open' : 'close']"
      id="sitecontent"
    >
      <!-- 头部 -->
      <Header />
      <!-- 内容 -->
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component
            :is="Component"
            :key="$route.name"
            v-if="$route.meta.keepAlive"
            @setScrollTop="setScrollTop"
          />
        </keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
          @setScrollTop="setScrollTop"
        />
      </router-view>
      <!-- 尾部 -->
      <Footer />
      <!-- 回顶部 -->
      <el-backtop :right="50" :bottom="50" target="#sitecontent .el-scrollbar__wrap" />
    </el-scrollbar>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import type { ElScrollbar } from 'element-plus'
import { useStore } from '@/store'
import Header from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'
import MobMenu from '@/layout/MobMenu/MobMenu.vue'

const store = useStore()
const route = useRoute()

const reactiveData = reactive({
  // 手机菜单开关
  menuState: computed(() => store.state.LayOutFactoryModule.menuState)
})

// 设置滚动条位置
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()
const setScrollTop = (value: number) => {
  scrollbarRef.value!.setScrollTop(value)
}

// 监听路由，有变化让滚动条置顶
watch(
  () => route.fullPath,
  (val, old) => {
    setScrollTop(0)
  }
)
</script>

<style lang="less" scoped>
.main {
  #sitecontent {
    height: 100vh;
    min-height: unset;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .open {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: translate3d(259px * @fixMobile, 0, 0);
  }

  .close {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: translate3d(0, 0, 0);
  }

  .el-backtop {
    color: unset;
    background: rgba(0, 0, 0, 0.8);
  }
}

// 手机、平板
@media screen and (max-width: 900px) {
  .main {
    .el-backtop {
      width: 40px;
      height: 40px;
      right: 10px !important;
    }
  }
}
</style>
