<!--
 * @Author: zhanghan
 * @Date: 2022-03-23 15:00:33
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-22 19:56:10
 * @Descripttion: 手机端的菜单
-->
<template>
  <ul class="mob-nav" :style="{ zIndex: menuState ? 100 : 0 }">
    <!-- 菜单 -->
    <mob-menu-item
      v-model="routerPath"
      v-for="(item, index) in menuList"
      :key="index"
      :menuInfo="item"
    />
  </ul>
  <!-- 手机菜单开关按钮 -->
  <mob-menu-btn v-model="menuState" />
  <!-- 遮罩 -->
  <div :class="['nav-mask', menuState ? 'nav-mask-open' : '']" @click="closeMenu"></div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { travelTrees } from '@/utils'

import MobMenuItem from '@/layout/MobMenu/MobMenuItem.vue'
import MobMenuBtn from '@/layout/MobMenu/MobMenuBtn.vue'

const props = defineProps({
  closeOnClickModal: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
const store = useStore()

// 菜单配置项数据
const MenuConfig = window.MENU_CONFIG
// 数据深拷贝
const menuList = JSON.parse(JSON.stringify(MenuConfig.menuList))
// 计算树深度
travelTrees(menuList, 'children', (node, deep) => {})

// 初始化当前选择的菜单
const routerPath = ref('/')
// 监听链接参数是否变化，有变化重新指定选择菜单
watchEffect(() => {
  routerPath.value = decodeURIComponent(route.fullPath)
})

// 初始化获取菜单状态
const menuState = ref(store.state.LayOutFactoryModule.menuState)
// 监听全局菜单状态转发给菜单组件
watch(
  () => store.state.LayOutFactoryModule.menuState,
  (val, old) => {
    menuState.value = val
  }
)
// 监听菜单状态转发给全局菜单状态
watch(
  () => menuState.value,
  (val, old) => {
    // 传递菜单开合状态
    store.commit('LayOutFactoryModule/SET_MENU_STATE', val)
  }
)

// 菜单开关事件
function closeMenu() {
  // 	是否可以通过点击 遮罩关闭菜单
  if (props.closeOnClickModal) {
    // 传递菜单开合状态
    store.commit('LayOutFactoryModule/SET_MENU_STATE', false)
  }
}
</script>

<style lang="less" scoped>
.mob-nav {
  width: 260px * @fixMobile;
  height: calc(100% - 50px * @fixMobile);
  background: #1a1b1c;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 50px * @fixMobile;
}

.nav-mask {
  width: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 1900;
  right: 0;
}

.nav-mask-open {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  width: calc(100% - 260px * @fixMobile);
}
</style>
