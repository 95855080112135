/*
 * @Author: zhanghan
 * @Date: 2022-01-27 18:44:26
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-24 10:33:30
 * @Descripttion: 主入口文件
 */
// 引入兼容补丁
import '@/utils/polyfills'

import { createApp } from 'vue'
import router from '@/router/index'
import { key, store } from '@/store'
import App from '@/App.vue'

// 引入基础样式
import '@/assets/style/basic.less'
// 引入官网样式
import '@/assets/style/lib.css'
import '@/assets/style/style.css'
import '@/assets/style/263.css'

// 引入自定义指令
import * as directives from '@/utils/derective'

// 引入路由拦截处理方法
import { beforeEachHandler, afterEachHandler } from '@/permission'

const app = createApp(App)
// 使用自定义指令
Object.entries(directives).forEach(([key, value]) => {
  app.directive(key, value)
})
// 使用路由拦截处理方法
router.beforeEach(beforeEachHandler)
router.afterEach(afterEachHandler)

app.use(router).use(store, key).mount('#app')
