<!--
 * @Author: zhanghan
 * @Date: 2022-03-23 15:00:33
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-12 21:10:30
 * @Descripttion: pc端的菜单项
-->
<template>
  <li
    class="navitem"
    @mouseover="mouseover(menuInfo.webUrl)"
    @mouseout="mouseout(menuInfo.webUrl)"
  >
    <!-- 父菜单 -->
    <app-link
      :to="menuInfo.webUrl"
      @click="selectClass(menuInfo.webUrl, menuInfo.children.length > 0)"
      :class="[
        menuInfo.webUrl === '/'
          ? modelValue === menuInfo.webUrl
            ? 'active'
            : ''
          : (Array.isArray(menuInfo.children) &&
              menuInfo.children.filter((item) => item.webUrl == modelValue).length >
                0) ||
            (menuInfo.webUrl && modelValue.indexOf(menuInfo.webUrl) > -1)
          ? menuInfo.deep == 1
            ? 'active'
            : 'subnav-active'
          : ''
      ]"
    >
      {{ menuInfo.name }}
      <span class="more" v-if="menuInfo.children.length">
        <i :class="['fa', isOpen ? 'fa-angle-up' : 'fa-angle-down']"></i>
      </span>
    </app-link>
    <!-- 子菜单 -->
    <ul class="subnav" v-show="isOpen && menuInfo.children.length">
      <!-- 普通子菜单的情况 -->
      <menu-item
        v-if="Array.isArray(menuInfo.children)"
        v-model="modelValue"
        v-for="(item, index) in menuInfo.children"
        :key="index"
        :menuInfo="item"
      />
      <!-- 子菜单是分类名称作为子菜单的情况 -->
      <li v-for="(item, key) in classObj" v-else>
        <app-link
          :to="{ path: menuInfo.webUrl, query: { class: key } }"
          :class="[
            modelValue.indexOf(`${menuInfo.webUrl}?class=${key}`) > -1
              ? 'subnav-active'
              : ''
          ]"
          @click="selectClass(`${menuInfo.webUrl}?class=${key}`)"
          >{{ key }}</app-link
        >
      </li>
    </ul>
  </li>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { getSortClass } from '@/utils/sort'

import AppLink from '@/components/commom/AppLink.vue'

// 菜单是否展开
const isOpen = ref(false)

const props = defineProps({
  // 菜单信息
  menuInfo: {
    type: Object,
    default: {}
  },
  // 当前路由路径（作为选中菜单匹配标识）
  modelValue: {
    type: String,
    default: ''
  }
})

// 首页配置项数据
const HomeConfig = window.HOME_CONFIG
// 数据深拷贝
let sortList = []
// 如果是字符串，通过home.config.js的指定字段去获取分类名称作为子菜单
if (!Array.isArray(props.menuInfo.children)) {
  sortList = JSON.parse(JSON.stringify(HomeConfig[props.menuInfo.children]))
}

// 获取分类列表
const classObj = ref(<any>{})
classObj.value = getSortClass(sortList, 'class')

const emit = defineEmits(['update:modelValue'])

// 菜单选取事件
function selectClass(key: string = '', openCtrol: boolean = false) {
  // 菜单是否展开
  if (openCtrol) {
    isOpen.value = true
  }
  // 设置选中项
  emit('update:modelValue', key)
}

// 菜单鼠标移入事件
function mouseover(key: string = '') {
  isOpen.value = true
}

// 菜单鼠标移出事件
function mouseout(key: string = '') {
  isOpen.value = false
}
</script>

<style lang="less" scoped>
.navitem {
  white-space: nowrap;
  .more {
    width: 12px;
    height: 12px;
    position: relative;
    display: inline-block;
    margin-left: 5px !important;

    i {
      opacity: unset !important;
      margin-top: 2px !important;
    }
  }
  .subnav {
    display: flex;
    flex-direction: column;
    left: 0 !important;
    top: unset !important;
    width: 100% !important;
    a {
      width: unset !important;
    }
    .navitem {
      padding: 0 !important;
      .subnav {
        top: -3px !important;
        left: -100% !important;
      }
    }
    .subnav-active {
      text-indent: 5px !important;
      background: #1b1b1b !important;
      color: #fff !important;
    }
  }
}
</style>
