/*
 * @Author: zhanghan
 * @Date: 2022-02-24 19:49:47
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-01 11:25:20
 * @Descripttion: vuex主入口
 */
import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import RootStateTypes, { AllStateTypes } from './types'
import getters from './getters'

import numFactoryModule from './modules/NumFactory'
import LayOutFactoryModule from './modules/LayOutFactory'

export const store = createStore<RootStateTypes>({
  state: {
    test: '123456'
  },
  getters,
  mutations: {},
  actions: {},
  modules: {
    numFactoryModule,
    LayOutFactoryModule
  }
})

export const key: InjectionKey<Store<RootStateTypes>> = Symbol('vue-store')

export function useStore<T = AllStateTypes>() {
  return baseUseStore<T>(key)
}
