<!--
 * @Author: zhanghan
 * @Date: 2022-02-07 23:47:07
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-12 17:19:39
 * @Descripttion: 跳转组件
-->
<template>
  <a
    v-if="isExternal"
    :href="to"
    :target="$props.target ? $props.target : '_blank'"
    rel="noopener"
  >
    <slot />
  </a>
  <router-link v-else v-bind="$props">
    <slot />
  </router-link>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { RouterLink } from 'vue-router'

const props = defineProps({
  ...RouterLink.props
})

const isExternal = computed(
  () => typeof props.to === 'string' && props.to.startsWith('http')
)
</script>

<style lang="less" scoped></style>
