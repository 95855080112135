/*
 * @Author: zhanghan
 * @Date: 2022-03-04 09:35:29
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-18 23:54:55
 * @Descripttion: 一些零碎的工具函数
 */
import router from '@/router'

// 显式跳转
export function jumpUrl(url: string, param = {}) {
  if (url.startsWith('http')) {
    window.location.href = url
    return
  }
  router.push({ path: url, query: param })
}

// 数组打乱
export function shuffle(array: Array<any>) {
  const arrayCopy = array.slice(0)
  let m = arrayCopy.length
  let t
  let i
  while (m) {
    i = Math.floor(Math.random() * m--)
    t = arrayCopy[m]
    arrayCopy[m] = arrayCopy[i]
    arrayCopy[i] = t
  }
  return arrayCopy
}

/**
 * 遍历深林并计算深度(广度优先)
 * @param {*} trees 树形数组
 * @param {*} childName 树形数组子集名称
 * @param {*} callback(node, deep)
 */
export function travelTrees(trees, childName, callback) {
  // 浅拷贝
  const queue = trees.slice(0)
  // 初始化根节点深度, 从1开始
  queue.map((node) => (node.deep = 1))

  for (let i = 0; i < queue.length; i++) {
    const node = queue[i]
    callback(node, node.deep)
    if (node[childName] && node[childName].length && Array.isArray(node[childName])) {
      // 计算子节点深度
      node[childName].map((child) => (child.deep = node.deep + 1))
      queue.push(...node[childName])
    }
  }
}

/**
 * 判断 h5所在的环境
 * @returns environment
 */
export function discernEnvironment() {
  let environment = ''
  const { userAgent } = window.navigator
  const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  const isWeixin = /micromessenger/i.test(userAgent)
  const isAlipay = /Alipay/i.test(userAgent)
  const isWxminiprogram =
    /miniProgram/i.test(userAgent) && /micromessenger/i.test(userAgent)
  const isZfbminiprogram = /MiniProgram/i.test(userAgent) && /Alipay/i.test(userAgent)
  if (isWxminiprogram) {
    // 微信小程序渠道
    environment = 'wxMiniProgram'
  } else if (isZfbminiprogram) {
    // 支付宝小程序渠道
    environment = 'zfbMiniProgram'
  } else if (isWeixin) {
    // 微信渠道
    environment = 'weixin'
  } else if (isAlipay) {
    // 支付宝渠道
    environment = 'alipay'
  } else if (isAndroid) {
    // 支付宝渠道
    environment = 'androidH5'
  } else if (isiOS) {
    // 苹果测试渠道
    environment = 'iosH5'
  } else {
    // 未知测试渠道
    environment = 'H5'
  }
  return environment
}

/**
 * 获取设备和系统信息
 * @returns environment
 */
export function getMobSysInfo() {
  Array.prototype.contains = function (needle) {
    for (i in this) {
      if (this[i].indexOf(needle) > 0) return i
    }
    return -1
  }

  const device_type = navigator.userAgent // 获取userAgent信息
  const md = new MobileDetect(device_type) // 初始化mobile-detect
  let os = md.os() // 获取系统
  let model = ''
  if (os == 'iOS') {
    // ios系统的处理
    os = md.os() + md.version('iPhone')
    model = md.mobile()
  } else if (os == 'AndroidOS') {
    // Android系统的处理
    os = md.os() + md.version('Android')
    const sss = device_type.split(';')
    var i = sss.contains('Build/')
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf('Build/'))
    }
  }
  return {
    os,
    model
  }
}

// 判断是移动设备还是电脑
export function IsPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  )
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

// 设置站点标题
export function setTitle(title: string) {
  document.title = title
  document.head.querySelector('title').innerText = title
}
