/*
 * @Author: zhanghan
 * @Date: 2021-09-29 16:36:17
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-01 11:32:13
 * @Descripttion: getters
 */
import { discernEnvironment, getMobSysInfo } from '@/utils'

export default {
  nowEnv: () => discernEnvironment(), // 当前环境
  sysInfo: () => getMobSysInfo() // 设备信息
}
