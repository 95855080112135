<!--
 * @Author: zhanghan
 * @Date: 2022-01-27 18:44:26
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-05-20 14:44:29
 * @Descripttion: 底部组件
-->
<template>
  <div id="footer">
    <p>
      (©) 2021 - {{ new Date().getFullYear() }}
      <a href="/#/about">{{ GlobalConfig.name }}</a>
      <span class="contact-us shake">
        <i class="fa fa-phone"></i>
        <a href="/#/?index=9">联系我们</a>
      </span>
    </p>
  </div>
  <div id="shares">
    <a>
      <i class="fa fa-share-alt"></i>
    </a>
    <a target="_blank" id="sweibo">
      <i class="fa fa-weibo"></i>
    </a>
    <a href="javascript:;" id="sweixin">
      <i class="fa fa-weixin"></i>
    </a>
    <a href="javascript:;" id="sweixin" @click="onlineOpen = !onlineOpen">
      <i class="fa fa-angle-down" v-if="onlineOpen"></i>
      <i class="fa fa-comments-o" v-else></i>
    </a>
  </div>
  <div class="fixed" id="fixed_weixin">
    <div class="fixed-container">
      <div id="qrcode"></div>
      <p>扫描二维码分享到微信</p>
    </div>
  </div>
  <div id="online_lx" v-if="onlineOpen">
    <div id="olx_head">
      在线咨询
      <i class="fa fa-times fr" id="online_close" @click="onlineOpen = false"></i>
    </div>
    <ul id="olx_qq">
      <li v-if="!GlobalConfig.contactInfo['wechat'].textHidden">
        <a :href="GlobalConfig.contactInfo['wechat'].link">
          <i :class="['fa', GlobalConfig.contactInfo['wechat'].icon]"></i>
          {{ GlobalConfig.contactInfo['wechat'].value }}
        </a>
      </li>
      <li v-if="!GlobalConfig.contactInfo['qq'].textHidden">
        <a :href="GlobalConfig.contactInfo['qq'].link">
          <i :class="['fa', GlobalConfig.contactInfo['qq'].icon]"></i>
          {{ GlobalConfig.contactInfo['qq'].value }}
        </a>
      </li>
    </ul>
    <div id="olx_tel" v-if="!GlobalConfig.contactInfo['phone'].textHidden">
      <div style="line-height: 20px; height: 20px">
        <i
          :class="['fa', GlobalConfig.contactInfo['phone'].icon]"
          style="font-size: 23px"
        ></i>
        {{ GlobalConfig.contactInfo['phone'].name }}
      </div>
      <p>
        {{ GlobalConfig.contactInfo['phone'].value }}
        <br />
      </p>
    </div>
    <div id="olx_tel" v-if="!GlobalConfig.contactInfo['tel'].textHidden">
      <div>
        <i :class="['fa', GlobalConfig.contactInfo['tel'].icon]"></i>
        {{ GlobalConfig.contactInfo['tel'].name }}
      </div>
      <p>
        {{ GlobalConfig.contactInfo['tel'].value }}
        <br />
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

// 全局配置项数据
const GlobalConfig = window.GLOBAL_CONFIG

const onlineOpen = ref<boolean>(false)
</script>

<style lang="less" scoped>
#footer {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  z-index: 9999;
  p {
    height: 20px;
    line-height: 20px;
  }
  a {
    margin-top: 1px;
    display: inline-block;
    height: 20px;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 5px;
    color: #fdd947;
  }
  a path {
    fill: #ff0000;
  }
}

// pc
@media screen and (min-width: 900px) {
  #footer {
    p {
      text-align: right;
      padding: 8px 240px;
      .contact-us {
        display: none;
      }
    }
  }
  #shares {
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-items: center;
    align-items: center;
    a {
      height: 36px;
      line-height: 36px;
      vertical-align: top;
      i {
        margin-top: 0;
      }
    }

    a#gotop {
      margin-top: 0;
    }
  }

  #online_lx {
    width: 180px;
    bottom: 36px;
  }

  #olx_tel {
    padding: 10px 30px;
    text-align: center;
    p {
      font-size: 15px;
    }
  }
}

// 手机、平板
@media screen and (max-width: 900px) {
  #footer {
    p {
      padding: 10px 20px !important;
      .contact-us {
        display: unset;
        float: right;
        border: 1px solid;
        padding: 0 5px;
        border-radius: 10px;
      }
    }
  }
  #shares {
    display: none !important;
  }
}
</style>
