/*
 * @Author: zhanghan
 * @Date: 2022-03-04 12:29:00
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-03-04 12:29:22
 * @Descripttion: 设置Cookies
 */

function setCookie(key, value, iDay) {
  const oDate = new Date()
  oDate.setDate(oDate.getDate() + iDay)
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  value = encodeURIComponent(value)
  document.cookie = `${key}=${value};expires=${oDate};path=/`
}

function removeCookie(key) {
  setCookie(key, '', -1) // 这里只需要把Cookie保质期退回一天便可以删除
}

function getCookie(key) {
  const cookieArr = document.cookie.split('; ')
  for (let i = 0; i < cookieArr.length; i++) {
    const arr = cookieArr[i].split('=')
    if (arr[0] === key) {
      try {
        if (decodeURIComponent(arr[1]).indexOf('}') > -1) {
          return JSON.parse(decodeURIComponent(arr[1]))
        }
        return decodeURIComponent(arr[1])
      } catch (err) {
        return err
      }
    }
  }
  return false
}

function clearCookie() {
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = `${keys[i]}=0;path=/;expires=${new Date(0).toUTCString()}`
    }
  }
}

export default {
  setCookie,
  removeCookie,
  getCookie,
  clearCookie
}
