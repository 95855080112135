<!--
 * @Author: zhanghan
 * @Date: 2022-03-23 15:00:33
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-15 10:45:52
 * @Descripttion: 手机端的菜单项
-->
<template>
  <li class="navitem">
    <!-- 父菜单 -->
    <app-link
      :to="menuInfo.webUrl"
      @click="selectClass(menuInfo.webUrl, menuInfo.children.length > 0)"
      :name="menuInfo.routerName"
      :class="[
        menuInfo.webUrl === '/'
          ? modelValue === menuInfo.webUrl
            ? 'active'
            : ''
          : (Array.isArray(menuInfo.children) &&
              menuInfo.children.filter((item) => item.webUrl == modelValue).length >
                0) ||
            (menuInfo.webUrl && modelValue.indexOf(menuInfo.webUrl) > -1)
          ? menuInfo.deep == 1
            ? 'active'
            : 'subnav-active'
          : ''
      ]"
    >
      <span class="circle"></span>
      {{ menuInfo.name }}
      <span class="more" v-if="menuInfo.children.length">
        <span class="h"></span>
        <span :class="['h', isOpen ? '' : 'v', 'transform']"></span>
      </span>
    </app-link>
    <!-- 子菜单 -->
    <ul class="subnav" v-show="isOpen && menuInfo.children.length">
      <!-- 普通子菜单的情况 -->
      <mob-menu-item
        v-if="Array.isArray(menuInfo.children)"
        v-model="modelValue"
        v-for="(item, index) in menuInfo.children"
        :key="index"
        :menuInfo="item"
      />
      <!-- 子菜单是分类名称作为子菜单的情况 -->
      <li v-for="(item, key) in classObj" v-else>
        <app-link
          :to="{ path: menuInfo.webUrl, query: { class: key } }"
          :class="[
            modelValue.indexOf(`${menuInfo.webUrl}?class=${key}`) > -1
              ? 'subnav-active'
              : ''
          ]"
          @click="selectClass(`${menuInfo.webUrl}?class=${key}`)"
          >{{ key }}</app-link
        >
      </li>
    </ul>
  </li>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'

import { getSortClass } from '@/utils/sort'

import AppLink from '@/components/commom/AppLink.vue'

const store = useStore()

// 菜单是否展开
const isOpen = ref(false)

const props = defineProps({
  // 菜单信息
  menuInfo: {
    type: Object,
    default: {}
  },
  // 当前路由路径（作为选中菜单匹配标识）
  modelValue: {
    type: String,
    default: ''
  }
})

// 动态设置单个菜单的左侧边距
const subnavPaddingLeft = ref(`${25 + props.menuInfo.deep * 10}px`)

// 首页配置项数据
const HomeConfig = window.HOME_CONFIG
// 数据深拷贝
let sortList = []
// 如果是字符串，通过home.config.js的指定字段去获取分类名称作为子菜单
if (!Array.isArray(props.menuInfo.children)) {
  sortList = JSON.parse(JSON.stringify(HomeConfig[props.menuInfo.children]))
}

// 获取分类列表
const classObj = ref(<any>{})
classObj.value = getSortClass(sortList, 'class')

const emit = defineEmits(['update:modelValue'])

// 菜单选取事件
function selectClass(key: string = '', openCtrol: boolean = false) {
  // 菜单是否展开
  if (openCtrol) {
    isOpen.value = !isOpen.value
  } else {
    // 不能展开的项选中后关闭菜单
    store.commit('LayOutFactoryModule/SET_MENU_STATE', false)
  }
  // 设置选中项
  emit('update:modelValue', key)
}
</script>

<style lang="less" scoped>
.navitem {
  display: block;
  min-height: 49px * @fixMobile;
  border-top: 1px solid #313233;
  width: 100%;
  a {
    display: block;
    color: #b7b7b7;
    padding-left: 25px;
    padding-right: 25px;
    height: 49px * @fixMobile;
    line-height: 49px * @fixMobile;
    font-size: 1.2rem;
    font-family: 'HELVETICANEUELTPRO-THEX';
    span {
      display: inline-block;
    }
    .circle {
      border-color: #a2a2a2;
      width: 8px;
      height: 8px;
      border: #474747 1px solid;
      border-radius: 5px;
      margin-right: 20px * @fixMobile;
    }

    .more {
      width: 12px * @fixMobile;
      height: 12px * @fixMobile;
      position: relative;
      float: right;
      top: 20px * @fixMobile;
      display: inline-block;
      .h {
        width: 12px;
        height: 2px;
        transform: translate3d(0, 5px, 0);
        background: #474747;
        position: absolute;
      }
      .v {
        transform: rotate3d(0, 0, 1, 90deg) translate3d(5px, 0, 0);
      }

      .transform {
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      }
    }
  }

  .active {
    .circle {
      background-color: #a2a2a2;
    }
    a {
      background: rgba(34, 35, 36);
    }
  }

  .subnav {
    :deep(a) {
      padding-left: v-bind(subnavPaddingLeft);
    }
  }
  .subnav-active {
    color: #fdd947;
    .circle {
      background-color: #a2a2a2;
    }
    a {
      background: rgba(34, 35, 36);
    }
  }
}
</style>
