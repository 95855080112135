/*
 * @Author: zhanghan
 * @Date: 2020-08-06 09:46:14
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-02 16:26:23
 * @Descripttion: 页面鉴权
 */
import { setTitle } from '@/utils'
// 全局配置项数据
const GlobalConfig = window.GLOBAL_CONFIG

// 路由进入之前
export function beforeEachHandler(to, from) {
  console.log('to', to, 'from', from)
  if (to.matched.length) {
    return true
  }
  return false
}

// 路由进入之后
export function afterEachHandler(to, from) {
  // 设置标题
  let { title } = to.meta
  title || (title = to.name)
  if (title) {
    setTitle(`${GlobalConfig.name}-${title}`)
  }
}
