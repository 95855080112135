<!--
 * @Author: zhanghan
 * @Date: 2022-03-23 15:00:33
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-12 21:08:33
 * @Descripttion: pc端的菜单
-->
<template>
  <ul id="nav">
    <!-- 菜单 -->
    <menu-item
      v-model="routerPath"
      v-for="(item, index) in menuList"
      :key="index"
      :menuInfo="item"
    />
  </ul>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { travelTrees } from '@/utils'

import MenuItem from '@/layout/Menu/MenuItem.vue'

const route = useRoute()

// 菜单配置项数据
const MenuConfig = window.MENU_CONFIG

// 数据深拷贝
const menuList = JSON.parse(JSON.stringify(MenuConfig.menuList))

// 计算树深度
travelTrees(menuList, 'children', (node, deep) => {})

// 初始化当前选择的菜单
const routerPath = ref('/')

// 监听链接参数是否变化，有变化重新指定选择菜单
watchEffect(() => {
  routerPath.value = decodeURIComponent(route.fullPath)
  console.log('routerPath.value', routerPath.value)
})
</script>

<style lang="less" scoped>
// 手机、平板
@media screen and (max-width: 900px) {
  #nav {
    display: none;
  }
}
</style>
