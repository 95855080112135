/*
 * @Author: zhanghan
 * @Date: 2022-03-15 14:07:07
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-25 13:47:58
 * @Descripttion: 数据筛选过滤工具
 */

// 首页配置项数据
const HomeConfig = window.HOME_CONFIG
// 数据深拷贝
const productList = JSON.parse(JSON.stringify(HomeConfig.productList))
const newsList = JSON.parse(JSON.stringify(HomeConfig.newsList))

/**
 * 根据分类查找产品列表
 * @param sortName 分类名称
 * @returns
 */
export function fitterProductList(sortName: string) {
  return sortName ? productList.filter((item) => item.class === sortName) : productList
}

/**
 * 获取产品分类列表
 * @param selectAll 是否可以加入全部分类名称
 * @returns
 */
export function getProductClass(selectAll: boolean = false) {
  return getSortClass(productList, 'class', selectAll)
}

/**
 * 根据分类查找新闻列表
 * @param sortName 分类名称
 * @returns
 */
export function fitterNewsList(sortName: string) {
  return sortName ? newsList.filter((item) => item.class === sortName) : newsList
}

/**
 * 获取新闻分类列表
 * @param selectAll 是否可以加入全部分类名称
 * @returns
 */
export function getNewsClass(selectAll: boolean = false) {
  return getSortClass(newsList, 'class', selectAll)
}

/**
 * 通用获取数据分类
 * @param list 数据列表
 * @param sortName 分类字段名称
 * @param selectAll 是否可以加入全部分类名称
 * @param selectAllName 全部分类名称自定义
 * @returns
 */
export function getSortClass(
  list: Array<any>,
  sortName: string,
  selectAll: boolean = false,
  selectAllName: string = '全部'
) {
  // 获取产品分类列表
  const classObj = <any>{} // 创建一个对象，用来创建索引

  // 提取产品分类标签，去重和统计产品出现次数
  list.forEach((item, index) => {
    if (!classObj[item[sortName]]) {
      // 利用对象key索引，判断是否已有该标签分类字段
      classObj[item[sortName]] = {
        // 没有则创建key索引
        [sortName]: item[sortName],
        num: 1 // 初始统计1
      }
    } else {
      // 有则增加该重复次数统计
      ++classObj[item[sortName]].num
    }
  })

  return selectAll
    ? { [selectAllName]: { sortName: selectAllName, num: list.length }, ...classObj }
    : classObj
}

/**
 * 通用获取数据时间排序（取最新数据从近到远排序）
 * @param list 数据列表
 * @param sortName 分类字段名称
 * @returns
 */
export function getSortDate(list: Array<any>, sortName: string = 'date') {
  return list.sort((a, b) => {
    return new Date(b[sortName].replace(/-/g, '/')).getTime() <
      new Date(a[sortName].replace(/-/g, '/')).getTime()
      ? -1
      : 1
  })
}
