/*
 * @Author: zhanghan
 * @Date: 2019-07-26 02:04:09
 * @LastEditors: zhanghan 294333196@qq.com
 * @LastEditTime: 2023-09-19 05:14:44
 * @Descripttion: Bmob 配置参数
 */

// SDK 2.0以下需要使用
const ApplicationID = '' // SDK初始化必须用到此密钥
const RESTAPIKey = '' // REST API请求中HTTP头部信息必须附带密钥之一

// SDK 2.0以上需要使用
const SecretKey = '60c8d71aebad6b4a' // SDK安全密钥
const SafeKey = '131412' // API 安全码（在应用功能设置，安全验证，API安全码自己设置）

export { ApplicationID, RESTAPIKey, SecretKey, SafeKey }
