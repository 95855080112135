/*
 * @Author: zhanghan
 * @Date: 2022-03-21 15:33:57
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-05-18 16:04:31
 * @Descripttion: 自定义指令封装
 */

import { useIntersectionObserver } from '@vueuse/core'

// 未加载时默认url
const loadingImgUrl =
  'data:image/svg+xml;base64,PHN2ZyB0PSIxNjQ0ODk5MzI0NDgwIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIwOTMiIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNODc0LjEgODEzLjc1SDE0OS45Yy0yMi4yMiAwLTQwLjIzLTE4LjAxLTQwLjIzLTQwLjIzVjI1MC40OWMwLTIyLjIyIDE4LjAxLTQwLjIzIDQwLjIzLTQwLjIzaDcyNC4yYzIyLjIyIDAgNDAuMjMgMTguMDEgNDAuMjMgNDAuMjN2NTIzLjAzYzAgMjIuMjEtMTguMDIgNDAuMjMtNDAuMjMgNDAuMjN6TTI4MC42NiAzMTAuODRjLTM4Ljg5IDAtNzAuNDEgMzEuNTItNzAuNDEgNzAuNDFzMzEuNTIgNzAuNDEgNzAuNDEgNzAuNDEgNzAuNDEtMzEuNTIgNzAuNDEtNzAuNDEtMzEuNTItNzAuNDEtNzAuNDEtNzAuNDF6IG01MTIuOTcgMTAwLjU4YzAtMjIuMjItMTguMDEtNDAuMjMtNDAuMjMtNDAuMjNoLTQwLjIzYy02Ni42NiAwLTEyMC43IDU0LjA0LTEyMC43IDEyMC43djQwLjIzYzAgMzMuMzMtMjcuMDIgNjAuMzUtNjAuMzUgNjAuMzUtMTguMjkgMC0zNC40Ny04LjMxLTQ1LjU0LTIxLjE1LTAuMDUtMC4wNi0wLjI1LTAuMjgtMC4yOS0wLjMzLTIyLjA5LTI0LjA1LTU5Ljc3LTM4Ljg2LTk0Ljk4LTM4Ljg2LTAuNDQgMC0wLjg0IDAuMTItMS4yOCAwLjEzbC0wLjA2LTAuMDZjLTg4LjI2IDAuNzMtMTU5LjU5IDcyLjQ0LTE1OS41OSAxNjAuODYgMCAyMi4yMiAxOC4wMSA0MC4yMyA0MC4yMyA0MC4yM0g3NTMuNGMyMi4yMiAwIDQwLjIzLTE4LjAxIDQwLjIzLTQwLjIzVjQxMS40MnoiIHAtaWQ9IjIwOTQiIGZpbGw9IiNjZGNkY2QiPjwvcGF0aD48L3N2Zz4='

// 加载错误时代替
const errorImgUrl =
  'data:image/svg+xml;base64,PHN2ZyB0PSIxNjQ0ODk5ODEzMDQ1IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjQ0OTEiIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48cGF0aCBkPSJNNjQuMzgzMjM0IDUxMkM2NC4zODMyMzQgMjY0Ljc4NzgyOSAyNjQuNzg3ODI5IDY0LjM4MzIzNCA1MTIgNjQuMzgzMjM0IDc1OS4yMTIxNzEgNjQuMzgzMjM0IDk1OS42MTY3NjYgMjY0Ljc4NzgyOSA5NTkuNjE2NzY2IDUxMiA5NTkuNjE2NzY2IDc1OS4yMTIxNzEgNzU5LjIxMjE3MSA5NTkuNjE2NzY2IDUxMiA5NTkuNjE2NzY2IDI2NC43ODc4MjkgOTU5LjYxNjc2NiA2NC4zODMyMzQgNzU5LjIxMjE3MSA2NC4zODMyMzQgNTEyWk00NzQuMjMyMzc5IDc3MS4yNDUxMjRDNDc2LjQwODcxOCA3OTcuMzU1NTEyIDQ5MC41NTEzNzIgODEwLjQxMjEyMyA1MTYuNjYzMTc2IDgxMC40MTIxMjMgNTQyLjc3MzU2NCA4MTAuNDEyMTIzIDU1Ni45MTc2MzUgNzk3LjM1NTUxMiA1NTkuMDkyNTU2IDc3MS4yNDUxMjQgNTU2LjkxNzYzNSA3NDUuMTMzMzE5IDU0Mi43NzM1NjQgNzMwLjk5MDY2NiA1MTYuNjYzMTc2IDcyOC44MTQzMjcgNDkwLjU1MTM3MiA3MzAuOTkwNjY2IDQ3Ni40MDg3MTggNzQ1LjEzMzMxOSA0NzQuMjMyMzc5IDc3MS4yNDUxMjRaTTQ4MC43NTk5NzcgNjExLjMxNDc0OEM0ODAuNzU5OTc3IDYzNy40MjY1NTQgNDkyLjcyNzcxIDY1MC40ODE3NDcgNTE2LjY2MzE3NiA2NTAuNDgxNzQ3IDU0MC41OTcyMjYgNjUwLjQ4MTc0NyA1NTIuNTY0OTYgNjM3LjQyNjU1NCA1NTIuNTY0OTYgNjExLjMxNDc0OEw1NTIuNTY0OTYgMjQ5LjAyNDYxOEM1NTIuNTY0OTYgMjIyLjkxNDIzMSA1NDAuNTk3MjI2IDIwOS44NTc2MTkgNTE2LjY2MzE3NiAyMDkuODU3NjE5IDQ5Mi43Mjc3MSAyMDkuODU3NjE5IDQ4MC43NTk5NzcgMjIyLjkxNDIzMSA0ODAuNzU5OTc3IDI0OS4wMjQ2MThMNDgwLjc1OTk3NyA2MTEuMzE0NzQ4WiIgcC1pZD0iNDQ5MiIgZmlsbD0iI2NkY2RjZCI+PC9wYXRoPjwvc3ZnPg=='

// 查看图片自定义指令
export const previewImg = {
  mounted(el, binding, vnode) {
    // 提取图片元素
    const imgsElList = el.querySelectorAll('img')
    if (!imgsElList.length) {
      return
    }

    // console.log('imgsElList', imgsElList)

    // 获取图片地址数组
    const imgUrlList = <string[]>[]
    imgsElList.forEach((item: HTMLBaseElement) => {
      // 获取当前点击的图片链接
      const imgUrl = item.getAttribute('src')
      if (imgUrl) imgUrlList.push(imgUrl)
    })

    // 批量给图片设置点击事件
    let index = 0
    imgsElList.forEach((item: HTMLBaseElement) => {
      const myIndex = index
      const evt = () => {
        // 作为回调函数传入元素链接
        binding.value(myIndex, imgUrlList)
      }
      item.addEventListener('click', evt, false)
      index += 1
    })
  }
}

// 监听元素大小变化触发，返回元素信息
export const resize = {
  beforeMount(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    let width = ''
    let height = ''
    function isReize() {
      try {
        const style = document.defaultView.getComputedStyle(el)
        if (width !== style.width || height !== style.height) {
          binding.value({ width: style.width, height: style.height }) // 关键(这传入的是函数,所以执行此函数)
        }
        width = style.width
        height = style.height
      } catch (error) {
        // 如果监听不到元素，清除监听
        clearInterval(el.__vueSetInterval__)
      }
    }
    el.__vueSetInterval__ = setInterval(isReize, 300)
  },
  unmounted(el) {
    clearInterval(el.__vueSetInterval__)
  }
}

export const imgLazy = {
  mounted(el, binding) {
    if (binding.value === false) {
      return
    }

    // 提取图片元素
    const imgsElList = el.querySelectorAll('img')
    if (!imgsElList.length) {
      return
    }

    imgsElList.forEach((item: HTMLBaseElement) => {
      // 获取元素链接
      const imgUrl = item.getAttribute('src')
      item.setAttribute('src', loadingImgUrl) // 全部图片替换为默认加载图片
      const { stop } = useIntersectionObserver(
        item,
        ([{ isIntersecting }], observerElement) => {
          // 可见区域
          if (isIntersecting) {
            item.setAttribute('src', imgUrl) // 设置之前获取到的地址去请求
            // 当图片加载失败 设置为错误提示图片
            item.onerror = () => {
              item.setAttribute('src', errorImgUrl)
            }
            stop() // 可见区域后 下次不在执行监听
          }
        },
        { threshold: 0 } // 当可视区域宽高为0就触发
      )
    })
  }
}
