<!--
 * @Author: zhanghan
 * @Date: 2022-03-23 15:00:33
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-14 17:52:57
 * @Descripttion: 手机端的菜单按钮
-->
<template>
  <div @click="menuState" :class="['menu-btn', modelValue ? 'open' : 'close']">
    <div class="lcbody">
      <div class="lcitem top">
        <div class="rect top"></div>
      </div>
      <div class="lcitem bottom">
        <div class="rect bottom"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

// 菜单开关事件
function menuState() {
  emit('update:modelValue', !props.modelValue)
}
</script>

<style lang="less" scoped>
.menu-btn {
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  cursor: pointer;
  z-index: 2000;

  .lcbody {
    width: 30px;
    height: 30px;
    position: relative;

    .lcitem {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;

      .rect {
        width: 30px;
        height: 1px;
        background: #999999;
        position: absolute;
        left: 0;
        top: 14px;
      }
    }
  }
}

.open {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translate3d(260px * @fixMobile, 0, 0);

  .lcbody {
    transform: translateZ(0px) rotate(90deg);
    transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);

    .lcitem {
      transform: translateZ(0px) rotate(45deg);
      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.2s;

      .rect {
        transition: transform 0.2s ease 0s;
        transform: translate3d(0px, 0px, 0px);
      }
    }

    .bottom {
      transform: translateZ(0px) rotate(-45deg);
      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.2s;
    }
  }
}

.close {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translate3d(0px * @fixMobile, 0, 0);

  .lcbody {
    transform: translateZ(0px) rotate(0deg);
    transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);

    .lcitem {
      transform: translateZ(0px) rotate(0deg);
      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.2s;

      .rect {
        transition: transform 0.2s ease 0s;
        transform: translate3d(0px, 0px, 0px);
      }
    }

    .top {
      transform: translate3d(0px, -6px, 0px);
      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.2s;
    }

    .bottom {
      transform: translate3d(0px, 6px, 0px);
      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.2s;
    }
  }
}

// 手机、平板
@media screen and (max-width: 900px) {
  .menu-btn {
    display: block;
  }
}
</style>
