/*
 * @Author: zhanghan
 * @Date: 2022-03-03 16:32:02
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-03-03 16:32:25
 * @Descripttion: 兼容补丁
 */

// 因为 Vite 是 ESM 机制，有些包内部使用了 node 的 global 对象，解决此问题可以通过自建 pollfill，然后在 main.ts 顶部引入
if (typeof (window as any).global === 'undefined') {
  ;(window as any).global = window
}
