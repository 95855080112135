<!--
 * @Author: zhanghan
 * @Date: 2022-01-27 18:44:26
 * @LastEditors: zhanghan
 * @LastEditTime: 2022-04-01 12:15:29
 * @Descripttion:
-->
<template>
  <Main />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useStore } from '@/store'
import Ip from '@/api/ip'
import cookie from '@/utils/cookie'

import Main from '@/layout/Main.vue'

const store = useStore()

// 判断是否有ip信息缓存，没有再获取并缓存和记录
let ipInfo = cookie.getCookie('ipInfo') || ''
if (!ipInfo) {
  ipInfo = {
    ipv4: window.returnCitySN.cip,
    area: window.returnCitySN.cname,
    citycode: window.returnCitySN.cid,
    ...store.getters.sysInfo // 设备信息
  }
  // 缓存ip信息，过期时间为1天
  cookie.setCookie('ipInfo', ipInfo, 1)
  // 记录访客ip
  Ip.set(ipInfo)
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
